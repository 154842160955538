/* The fixed navbar will overlay your other content, unless you add padding to the top of the <body>. Try out your own values or use our snippet below. Tip: By default, the navbar is 50px high. */
body {
    padding-top: 70px;
}

.avatar {
    float: left;
    display: block;
    border-radius: 3px;
    margin-right: 2px;
}

.table {
    tbody {
        tr {
            td {
                vertical-align: middle;
            }
        }
    }
}
